import React from 'react';

import TransferCom from '@/components/common/transferCom';

import IpadEasyCloud from './ipad';
import MEasyCloud from './m';
import PcEasyCloud from './pc';

const EasyCloud: React.FC = () => {
    return <TransferCom m={<MEasyCloud />} ipad={<IpadEasyCloud />} pc={<PcEasyCloud />} />;
};
export default EasyCloud;
