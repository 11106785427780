import type { ReactNode } from 'react';
import React from 'react';

import Title from '@/components/common/title';

import styles from './styles.module.less';
import { homeCloudList } from '../contant';
import Link from 'next/link';

interface PcProps {
    title?: ReactNode;
    desc?: ReactNode;
}
const MCloudComputing: React.FC<PcProps> = (props) => {
    const { title, desc } = props;
    return (
        <div className={styles['cloud-container']}>
            <Title title={title} desc={desc} />
            <div className={styles.list}>
                {homeCloudList.map((item) => {
                    return (
                        <Link key={item.id} className={styles.item} href={item.url}>
                            <img loading="lazy" src={item.img} alt="" />
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};
export default MCloudComputing;
