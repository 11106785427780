import { Button } from 'antd';
import React from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';

import styles from './styles.module.less';
import { useRouter } from 'next/router';

const MEasyCloud: React.FC = () => {
    const router = useRouter();
    return (
        <div className={styles['cloud-container']}>
            <div className={styles['cloud-content']}>
                <p>{intl.get('Home.EasyCloudText')}</p>
                <div className={styles['more-btn-box']}>
                    <Button
                        className={styles['more-btn']}
                        type="primary"
                        size="large"
                        onClick={() => {
                            router.push('/contact/');
                        }}
                    >
                        {intl.get('Home.ConsultBtn')}
                        <IconFont type="icon-youjiantou" className={styles['right-arrow']}></IconFont>
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default MEasyCloud;
