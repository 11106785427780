import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';

import Banner from '@/components/common/banner';

import Advantage from './components/Advantage';
import CloudComputing from './components/CloudComputing';
import EasyCloud from './components/EasyCloud';
import Facilities from './components/Facilities';
import OneStop from './components/OneStop';
import { Button, Carousel, Space } from 'antd';
import styles from './index.module.less';
import classnames from 'classnames';
import { getCookie, toConsolePath } from '@/utils/utils';

const Home: React.FC = () => {
    const desc = intl.get('Home.BannerDesc');
    const [isLogin, setIsLogin] = useState<boolean>(false);
    useEffect(() => {
        if (getCookie('Authorization')) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, []);

    const BtnGroupRender = () => {
        const isEnLocale = intl.getInitOptions().currentLocale === 'en-US';
        return (
            <Space size={18} className="btn-group">
                <Button
                    type="primary"
                    size="large"
                    className={styles.btn}
                    aria-hidden="false"
                    onClick={(e) => {
                        e.stopPropagation();
                        toConsolePath({
                            path: isLogin ? '/cloud/desktop/list' : '/register',
                            isNotAuth: true,
                            isTarget: true,
                            isRedirect: true,
                        });
                    }}
                >
                    {isLogin
                        ? intl.get('Home.LoginHostBtnText').d('立即使用')
                        : intl.get('NewUser.HostBtnText').d('立即注册')}
                </Button>
                <Button
                    type="primary"
                    size="large"
                    aria-hidden="false"
                    className={classnames(styles.btn, styles['btn-r'], isEnLocale && styles['btn-r-en'])}
                    onClick={(e) => {
                        e.stopPropagation();
                        window.open('https://open-api-cloud.finovy.cn/doc-3930247');
                    }}
                >
                    {intl.get('CloudDesk.BannerBtnText2').d('产品文档')}
                </Button>
            </Space>
        );
    };

    return (
        <div className={styles['home-page']}>
            <Carousel autoplay autoplaySpeed={5000}>
                <div style={{ height: '100%' }}>
                    <Banner
                        type="img"
                        title={
                            <>
                                <div className={styles['banner-title']}>
                                    <span>{intl.get('CloudDesk.BannerNewTitle1').d('全新上线')}</span>
                                    <span>{intl.get('CloudDesk.BannerNewTitle2').d('免费体验')}</span>
                                </div>
                                <div className={styles['banner-desc']}>
                                    {intl.get('CloudDesk.BannerPcNewDesc').d('云桌面全新上线，新用户注册送100元！')}
                                </div>
                            </>
                        }
                        mTitle={
                            <div className={styles['banner-MTitle']}>
                                <span>{intl.get('CloudDesk.BannerNewTitle1').d('全新上线')}</span>
                                <span>{intl.get('CloudDesk.BannerNewTitle2').d('免费体验')}</span>
                            </div>
                        }
                        ipadDesc={intl.get('CloudDesk.BannerPcNewDesc').d('云桌面全新上线，新用户注册送100元！')}
                        mDesc={intl.get('CloudDesk.BannerPcNewDesc').d('云桌面全新上线，新用户注册送100元！')}
                        mShowBtn={true}
                        pcSrc="/business/oss-service/domestic-cloud-website/img/clouddesktop/pc/banner.jpg"
                        ipadSrc="/business/oss-service/domestic-cloud-website/img/clouddesktop/pc/banner.jpg"
                        mSrc="/business/oss-service/domestic-cloud-website/img/clouddesktop/m/banner.jpg"
                        btnRender={BtnGroupRender()}
                        isNeedGoTo
                        isNeedIpad={false}
                    />
                </div>
                <Banner
                    type="video"
                    title={intl.get('Home.BannerTitle')}
                    pcDesc={desc}
                    ipadDesc={desc}
                    mDesc={intl.get('Home.BannerMDesc')}
                    poster="/business/oss-service/domestic-cloud-website/img/home/pc/banner-poster.jpg"
                    videoSrc="/business/oss-service/domestic-cloud-website/img/home/pc/banner-v.mp4"
                    mSrc="/business/oss-service/domestic-cloud-website/img/home/m/banner.jpg"
                    ipadSrc="/business/oss-service/domestic-cloud-website/img/home/ipad/banner.jpg"
                    pcSrc="/business/oss-service/domestic-cloud-website/img/home/pc/banner.jpg"
                />
            </Carousel>

            {/* 携手全球领先的云计算提供商为您服务 */}
            <CloudComputing title={intl.get('Home.CloudComputingTitle')} desc={intl.get('Home.CloudComputingDesc')} />
            {/* 一站式IDC与云计算服务解决方案 */}
            <OneStop
                title={intl.get('Home.OneStopTitle')}
                mTitle={intl.get('Home.OneStopMTitle')}
                desc={intl.get('Home.OneStopDesc')}
            />
            {/* 我们的服务优势 */}
            <Advantage title={intl.get('Home.AdvantageTitle')} desc={intl.get('Home.AdvantageDesc')}></Advantage>
            <Facilities title={intl.get('Home.FacilitiesTitle')} desc={intl.get('Home.FacilitiesDesc')}></Facilities>
            {/* 轻松上云，即刻开启 */}
            <EasyCloud></EasyCloud>
        </div>
    );
};

export default Home;
