export const advantageList = [
    {
        id: '01',
        title: 'Home.AdvantageListTitle1',
        desc: 'Home.AdvantageListDesc1',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon1.png',
    },
    {
        id: '02',
        title: 'Home.AdvantageListTitle2',
        desc: 'Home.AdvantageListDesc2',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon2.png',
    },
    {
        id: '03',
        title: 'Home.AdvantageListTitle3',
        desc: 'Home.AdvantageListDesc3',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon3.png',
    },
    {
        id: '04',
        title: 'Home.AdvantageListTitle4',
        desc: 'Home.AdvantageListDesc4',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon4.png',
    },
    {
        id: '05',
        title: 'Home.AdvantageListTitle5',
        desc: 'Home.AdvantageListDesc5',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon5.png',
    },
    {
        id: '06',
        title: 'Home.AdvantageListTitle6',
        desc: 'Home.AdvantageListDesc6',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon6.png',
    },
    {
        id: '07',
        title: 'Home.AdvantageListTitle7',
        desc: 'Home.AdvantageListDesc7',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon7.png',
    },
    {
        id: '08',
        title: 'Home.AdvantageListTitle8',
        desc: 'Home.AdvantageListDesc8',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon8.png',
    },
];
