export const MapDataList = [
    {
        id: '01',
        title: '广东惠州',
        width: 28,
        height: 37,
        top: 160,
        ipadWidth: 20,
        ipadHeight: 25,
        mWidth: 20,
        marginLeft: -400,
        ipadTop: 25,
        ipadMarginLeft: -154,
        mTop: 0,
        mMarginLeft: -110,
        desc: 'Huizhou, Guangdong',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon1.png',
    },
    {
        id: '02',
        title: '中国香港',
        width: 35,
        height: 46,
        ipadWidth: 28,
        ipadHeight: 36,
        mWidth: 28,
        top: 190,
        marginLeft: 470,
        ipadTop: 40,
        ipadMarginLeft: 180,
        mTop: -6,
        mMarginLeft: 113,
        desc: 'Hong Kong, China',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon2.png',
    },
    {
        id: '03',
        title: '广东深圳',
        width: 35,
        height: 46,
        ipadWidth: 28,
        ipadHeight: 36,
        mWidth: 28,
        top: 340,
        marginLeft: 28,
        ipadTop: 95,
        ipadMarginLeft: 0,
        mTop: 40,
        mMarginLeft: 0,
        desc: 'Shenzhen, Guangdong',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon3.png',
    },
    {
        id: '04',
        title: '广西玉林',
        width: 24,
        height: 32,
        ipadWidth: 20,
        ipadHeight: 26,
        mWidth: 20,
        top: 472,
        marginLeft: -439,
        ipadTop: 130,
        ipadMarginLeft: -226,
        mTop: 80,
        mMarginLeft: -100,
        desc: 'Yulin, Guangxi',
        imgUrl: '/business/oss-service/domestic-cloud-website/img/home/advantage-icon4.png',
    },
];
