import type { ReactNode } from 'react';
import React from 'react';

import TransferCom from '@/components/common/transferCom';

import IpadOneStop from './ipad';
import MOneStop from './m';
import PcOneStop from './pc';

interface Iprops {
  title?: ReactNode
  desc?: ReactNode
  mTitle?: ReactNode
}
const OneStop: React.FC<Iprops> = (props) => {
  return <TransferCom m={<MOneStop {...props} />} ipad={<IpadOneStop {...props} />} pc={<PcOneStop {...props} />} />;
};
export default OneStop;
