export const homeCloudList = [
    {
        id: '01',
        img: '/business/oss-service/domestic-cloud-website/img/cloud/huawei-cloud.png',
        url: '/products/huawei/',
        alt: '华为云',
    },
    {
        id: '02',
        img: '/business/oss-service/domestic-cloud-website/img/cloud/baidu-cloud.png',
        url: '/products/baidu/',
        alt: '百度云',
    },
    {
        id: '03',
        img: '/business/oss-service/domestic-cloud-website/img/cloud/ali-cloud.png',
        url: '/products/ali/',
        alt: '阿里云',
    },
    {
        id: '04',
        img: '/business/oss-service/domestic-cloud-website/img/cloud/tengxun-cloud.png',
        url: '/products/tencent/',
        alt: '腾讯云',
    },
];
